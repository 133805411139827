import React from "react";

import { Container, SEOPage } from "../components";

import { StyledPage } from "../components/css";

import Portfolio from "../components/portfolio";

export default function PortfolioPage() {

  //On page SEO
  const SEO = {
    entity: "page",
    title: "Protfolio",
    keywords: `Property and facilities management,Accounting and reporting,Marketing,Asset management,Lease negotiation`,
    description: `Bridgeport Property Administration was formed to address the ever growing demand in the market for a transparent, professional property management company with a focus on tenant relationship management and hands on facilities and property management.`,
    url: `/portfolio`,
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "content", title: SEO.title }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        entity={SEO.entity}
        title={SEO.title}
        keywords={SEO.keywords}
        description={SEO.description}
        url={SEO.url}
      />

      <div style={{ padding: "0.5rem 1rem 0 1rem", maxWidth: 1200, margin: "0 auto 2rem auto" }}>
        <h1 style={{ marginBottom: 0 }}>Portfolio</h1>
      </div>

      <StyledPage>

        <Portfolio />

      </StyledPage>

    </Container>
  )
}